.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  margin: 10px;
  border-radius: 20px;
}
.MuiPaper-root-MuiPaper-elevation-MuiPaper-rounded-MuiPaper-elevation24-MuiDialog-paper-MuiDialog-paperScrollPaper-MuiDialog-paperWidthSm {
  margin: 10px;
  border-radius: 20px;
}
.MuiPaper-root-MuiPaper-elevation-MuiPaper-rounded-MuiPaper-elevation24-MuiDialog-paper-MuiDialog-paperScrollPaper-MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  margin: 10px;
  border-radius: 20px;
}
.css-uhb5lp {
  margin: 10px;
  border-radius: 20px;
}
.MuiPaper-root-MuiPaper-elevation-MuiPaper-rounded-MuiPaper-elevation24-MuiDialog-paper-MuiDialog-paperScrollPaper-MuiDialog-paperWidthSm.css-uhb5lp {
  margin: 10px;
  border-radius: 20px;
}
/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  margin: 0px;
  border-radius: 1px;
  box-shadow: none;
  background-color: transparent;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  margin: 0px;
  border-radius: 1px;
  box-shadow: none;
  background-color: transparent;
} */
