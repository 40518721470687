.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 15px;
  /* margin: 0px;
    box-shadow: none;
    background-color: rgb(255, 255, 255); */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  border-radius: 15px;
  /* margin: 0px;
      box-shadow: none;
      background-color: rgb(255, 255, 255); */
}
