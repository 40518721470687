.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.css-12rl710-MuiPaper-root-MuiDialog-paper {
  margin: 0px;
  border-radius: 1px;
  box-shadow: none;
  background-color: transparent;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  margin: 0px;
  border-radius: 1px;
  box-shadow: none;
  background-color: transparent;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.css-1qmc5dd {
  margin: 0px;
  border-radius: 1px;
  box-shadow: none;
  background-color: transparent;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.css-cwpu7v {
  margin: 0px;
  border-radius: 1px;
  box-shadow: none;
  background-color: transparent;
}
